<template>
  <router-view
    :viewPermission="viewPermission"
    :createPermission="createPermission"
    :editPermission="editPermission"
    :deletePermission="deletePermission"
  />
</template>

<script>
import { mapState } from 'vuex'
import accessMix from '@/services/accessmix.js'

export default {
  mixins: [accessMix],
  computed: {
    ...mapState(['user']),
    viewPermission() {
      return this.checkPermissions(this.user.permissions, 'fortune_wheel', 'view')
    },
    createPermission() {
      return this.checkPermissions(this.user.permissions, 'fortune_wheel', 'create')
    },
    editPermission() {
      return this.checkPermissions(this.user.permissions, 'fortune_wheel', 'edit')
    },
    deletePermission() {
      return this.checkPermissions(this.user.permissions, 'fortune_wheel', 'delete')
    },
  },
}
</script>
